import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class LoaderService {
    private currentValue: boolean = false;
    private currentAccessDeniedValue: boolean = false;
    private loaderSubject: Subject<boolean> = new Subject<boolean>(); // <EvaluationDocumentModel>(new EvaluationDocumentModel());
    private loaderObservable: Observable<boolean>;

    private accessDeniedSubject: Subject<boolean> = new Subject<boolean>();
    private accessDeniedObservable: Observable<boolean>;

    constructor() {
        this.loaderObservable = this.loaderSubject.asObservable();
        this.accessDeniedObservable = this.accessDeniedSubject.asObservable();
    }

    public getLoaderObservable(): Observable<boolean> {
        return this.loaderObservable;
    }

    public setLoaderValue(value: boolean) {
        if (this.currentValue !== value) {
            this.currentValue = value;
            this.loaderSubject.next(this.currentValue);
        }
    }

    public getAccessDeniedObservable(): Observable<boolean> {
        return this.accessDeniedObservable;
    }

    public setAccessDeniedValue(value: boolean) {
        if (this.currentAccessDeniedValue !== value) {
            this.currentAccessDeniedValue = value;
            this.accessDeniedSubject.next(this.currentAccessDeniedValue);
        }
    }
}