import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContractsService } from '../../../helpers/services/contracts.service';
import { AuthService } from '../../auth';
import { TranslateService } from '@ngx-translate/core';
import { LanguageUtilService } from '../../auth/language-util.service';
import { DialogService } from 'ng2-bootstrap-modal';
import { Subscription } from '../../../../../node_modules/rxjs';

@Component({
  selector: 'app-contract-implementor',
  templateUrl: './contract-implementor.component.html',
  styleUrls: ['./contract-implementor.component.scss']
})
export class ContractImplementorComponent implements OnInit, OnDestroy {
  public contractForm: FormGroup;
  public contractList;
  public subscription: Subscription;

  constructor(private formBuilder: FormBuilder, private contractService: ContractsService, public authService: AuthService,
    public translate: TranslateService, private languageUtilService: LanguageUtilService, private dialogService: DialogService) {
    if (localStorage.getItem('ContractsList') != null) {
      this.contractList = JSON.parse(localStorage.getItem('ContractsList'));
    }

    this.subscription = this.contractService.contractChangedEmitted$.subscribe(res => {
      if (localStorage.getItem('ContractsList') != null) {
        this.contractList = JSON.parse(localStorage.getItem('ContractsList'));
      }
    });
  }

  ngOnInit() {
    // create detailsForm FormGroup using FormBuilder's short-hand syntax
    this.contractForm = this.formBuilder.group({
      password: ['', Validators.required],
    });
  }

  openAccordion($event, i) {
    // console.log('tab open or closed event : ', $event, 'index : ', i);
  }


  signContract(contract) {
    const data = {
      'password': this.contractForm.value.password,
      'contractId': contract.id,
      'organizationId': contract.targetOrganizationId
    };

    this.contractService.signContract(data).subscribe((result) => {
      console.log('response signContract', result);
      if (result.contractList) {
          this.contractList = result.contractList;
          this.authService.setContractsList(result.contractList);
          this.contractService.contractSigned(true);
      }
    },
    (err) => {
      console.log('error', err);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
