import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastersComponent } from './toasters/toasters.component';
import { ToasterComponent } from './toaster/toaster.component';
import { ToasterService } from './toaster.service';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material.module';

@NgModule({
  imports: [
      CommonModule,
      TranslateModule,
      MaterialModule
  ],
  exports: [
      ToastersComponent
  ],
  declarations: [
      ToastersComponent,
      ToasterComponent
  ],
  providers: [
      ToasterService
  ]
})
export class ToastersModule { }
