
import {map} from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../auth';
import {environment} from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {LanguageUtilService} from '../../auth/language-util.service';
import { HttpClient } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-logout-warning',
  templateUrl: './logout-warning.component.html',
  styleUrls: ['./logout-warning.component.scss']
})
export class LogoutWarningComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  public showWarning: boolean;
  private hostPath: string = JSON.parse(environment.IDENTITY);

  public ngOnInit(): void {
  }

  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private http: HttpClient,
    public translate: TranslateService, private languageUtilService: LanguageUtilService) {
    this.authService.getUserAboutToGetTimedOutObservable()
      .subscribe((showWarning: boolean) => {
        this.showWarning = showWarning;
        if (this.showWarning) {
          // $('#logoutWarningModal').modal('show');
           $('#logoutWarningModal').alert();
        } else {
         // $('#logoutWarningModal').modal('hide');
           $('#logoutWarningModal').alert('close');
        }
        this.changeDetectorRef.markForCheck();
      });

      this.authService.getUserTimedOutObservable()
      .subscribe((username) => {
          $('#logoutWarningModal').alert('close');
      });
      // Need to initialize translate as its included only in navbar module
      translate.setDefaultLang(this.languageUtilService.getDefaultLang());
      translate.currentLang = this.languageUtilService.getDefaultLang();

      // Need to capture change events, because language-select component is not used in this module
      this.subscription = this.languageUtilService.langChangeObservable.subscribe(event => {
        translate.use(this.languageUtilService.getCurrentLang());
      });
  }

  public onClick() {
    $('#logoutWarningModal').alert('close');
    return this.http.get(this.hostPath + 'CJSF/Identity/cjsf/keepAlive').pipe(
      map((res) => res))
      .subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
