import {
  throwError as observableThrowError,
  Observable,
  BehaviorSubject,
  Subject
} from 'rxjs';
import { map, finalize, catchError } from 'rxjs/operators';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/Rx';
import { MemberRight } from './memberRight';
import { MemberType } from './memberType';
import { LoginResult } from './loginResult';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MATCHEDIN } from '../../app.constants';
import { CommunicationService } from '../../helpers/services/communication.service';
import { LanguageModel } from '../../helpers/models';
declare var appInsights: any;

@Injectable()
export class StateService {
  private hostPath: string = JSON.parse(environment.IDENTITY);

  constructor(
    private http: HttpClient,
    public router: Router,
    public authService: AuthService,
    private _myCommunicationService: CommunicationService
  ) { }

  public login(
    username: string,
    password: string,
    code?: string
  ): Observable<LoginResult> {
    console.log('Login');
    // var data = 'site=' + window.location.hostname + '&username=' + username + '&password=' + password + '&smscode=' + code;
    const data =
      'site=min.matchedin.com&username=' +
      username +
      '&password=' +
      password +
      '&smscode=' +
      code;

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    // this._myCommunicationService.loaderChange(true);
    return this.http
      .post<any>(this.hostPath + MATCHEDIN.APIS.LOGIN, data, { headers })
      .pipe(
        map((result: any) => {
          console.log('Response1');
          // login is successful if there's a jwt token in the response
          const loginResult = new LoginResult();
          loginResult.status = result.status;
          loginResult.contractList = result.contractList;
          const token = result.access_token;
          if (token) {
            this.authService.setToken(token);
            this.authService.username = username;
          }
          console.log('Response1');
          return loginResult;
        }),
        catchError((error: any) => {
          const loginResult = new LoginResult();
          const result = error;
          loginResult.status = result.status;
          return observableThrowError(loginResult);
        }),
        finalize(() => {
          this._myCommunicationService.loaderChange(false);
        })
      );
  }

  public activateUser(code: string, password: string): Observable<LoginResult> {
    // var data = 'site=' + window.location.hostname + '&username=' + username + '&password=' + password + '&smscode=' + code;
    const data = 'site=min.matchedin.com&code=' + code + '&password=' + password;

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    this._myCommunicationService.loaderChange(true);
    return this.http
      .post(this.hostPath + MATCHEDIN.APIS.ACTIVATE_USER, data, { headers })
      .pipe(
        map((result: any) => {
          const loginResult = new LoginResult();
          loginResult.success = result.success;
          loginResult.userCreateStatus = result.userCreateStatus;
          loginResult.status = result.status;
          return loginResult;
        }),
        catchError((error: any) => {
          const loginResult = new LoginResult();
          const result = error;
          loginResult.success = result.success;
          loginResult.userCreateStatus = result.userCreateStatus;
          loginResult.status = result.status;
          return observableThrowError(loginResult);
        }),
        finalize(() => {
          this._myCommunicationService.loaderChange(false);
        })
      );
  }

  public forgotPassword(email: string, language: string): Observable<LoginResult> {

    if (language === 'sv') {
      language = 'Svenska';
    } else if (language === 'en') {
      language = 'English';
    }

    const data = { site: 'min.matchedin.com', email, language };

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    this._myCommunicationService.loaderChange(true);
    return this.http
      .post(this.hostPath + MATCHEDIN.APIS.FORGOT_PASSWORD, data, { headers })
      .pipe(
        map((result: any) => {
          const loginResult = new LoginResult();
          loginResult.success = result.success;
          loginResult.userCreateStatus = result.userCreateStatus;
          loginResult.status = result.status;
          return loginResult;
        }),
        catchError((error: any) => {
          const loginResult = new LoginResult();
          const result = error;
          loginResult.success = result.success;
          loginResult.userCreateStatus = result.userCreateStatus;
          loginResult.status = result.status;
          return observableThrowError(loginResult);
        }),
        finalize(() => {
          this._myCommunicationService.loaderChange(false);
        })
      );
  }

  public resetPassword(
    code: string,
    password: string
  ): Observable<LoginResult> {
    // var data = 'site=' + window.location.hostname + '&username=' + username + '&password=' + password + '&smscode=' + code;
    const data = { site: 'min.matchedin.com', code, password };

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    this._myCommunicationService.loaderChange(true);
    return this.http
      .post(this.hostPath + MATCHEDIN.APIS.RESET_PASSWORD, data, { headers })
      .pipe(
        map((result: any) => {
          const loginResult = new LoginResult();
          loginResult.success = result.success;
          loginResult.userCreateStatus = result.userCreateStatus;
          loginResult.status = result.status;
          return loginResult;
        }),
        catchError((error: any) => {
          const loginResult = new LoginResult();
          const result = error;
          loginResult.success = result.success;
          loginResult.userCreateStatus = result.userCreateStatus;
          loginResult.status = result.status;
          return observableThrowError(loginResult);
        }),
        finalize(() => {
          this._myCommunicationService.loaderChange(false);
        })
      );
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }
}
