import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MATCHEDIN} from '../../app.constants';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class LanguageUtilService {

  private langChange = new Subject<any>();
  langChangeObservable = this.langChange.asObservable();

  // Service message
  constructor(private translate: TranslateService) {
    if (localStorage.getItem('DefaultLang')) {
      let lang = localStorage.getItem('DefaultLang');
      this.setDefaultLang(lang);
      this.changeLang(lang);
    } else {
      this.setDefaultLang(MATCHEDIN.CONSTANTS.DEFAULT_LANG);
      this.changeLang(MATCHEDIN.CONSTANTS.DEFAULT_LANG);
    }
    // change event listener
    translate.onLangChange.subscribe((event) => {
      // console.log('change event sdo', event);
    });
  }


  public setDefaultLang(lang?) {
    if (lang) {
      this.translate.setDefaultLang(lang);
    } else {
      if (localStorage.getItem('DefaultLang')) {
        let lang = localStorage.getItem('DefaultLang');
        this.translate.setDefaultLang(lang);
      } else {
        this.translate.setDefaultLang(MATCHEDIN.CONSTANTS.DEFAULT_LANG);
      }
    }
  }

  public getDefaultLang() {
    return this.translate.getDefaultLang();
  }


  public changeLang(lang: any) {
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);
    this.translate.currentLang = lang;
    this.langChange.next(lang);
    localStorage.setItem('DefaultLang', lang);
  }

  public getCurrentLang() {
    return this.translate.currentLang;
  }
}
