import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { LoaderService } from './loader.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [LoaderComponent],
    declarations: [LoaderComponent],
    providers: [LoaderService]
})
export class InterceptHttpModule { }
