import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ToasterService {
  private toasterAddedSub: Subject<Toaster> = new Subject<Toaster>();
  private toasterAdded$: Observable<Toaster> = this.toasterAddedSub.asObservable();
  private hostPath: string = JSON.parse(environment.IDENTITY);

  constructor(private authHttp: HttpClient) {
  }

  public keepAlive(): void {
    const data = { site: 'min.matchedin.com' };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    this.authHttp.post<any>(this.hostPath + 'cjsf/KeepAlive', data, { headers }).pipe(
      map((result: any) => {
        console.log('keepAlive result', result)
      }),
      catchError(this.handleError))
      .subscribe();
  }

  private handleError(error: Response | any) {
    // Todo: We should use a remote logging infrastructure
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error('toaster.service - Handled error: ' + errMsg); // log to console for now
    return observableThrowError(error);
    // Todo: How to handle this properly in component?
  }

  public toasterAdded(): Observable<Toaster> {
    return this.toasterAdded$;
  }

  public addToaster(toaster: Toaster) {
    this.toasterAddedSub.next(toaster);
  }
}

export interface Toaster {
  id?: any;
  type: ToasterType;
  icon?: string;
  header: string;
  message?: string;
  click?: (e: Event) => any;
  timeout?: number;
}

export type ToasterType = 'danger' | 'success';
