import { Component, HostListener } from '@angular/core';
import { DialogComponent, DialogService, DialogOptions } from 'ng2-bootstrap-modal';

export interface ConfirmModel {
  title: string;
  message: string;
  leftButton: string;
  rightButton: string;
  closeByClickingOutside: true;
}

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, DialogOptions {

  title: string;
  message: string;
  leftButton: string;
  rightButton: string;
  closeByClickingOutside: true;
  constructor(dialogService: DialogService) {
    super(dialogService);
  }
  confirm() {
    this.result = true;
    this.close();
  }
  cancel() {
    this.result = false;
    this.close();
  }


  @HostListener('window:keyup', ['$event']) onkeyUp(event) {
    if (event.key === 'Escape') {
    this.close();
    }
  }
  
}
