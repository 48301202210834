import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HttpClient } from "@angular/common/http";

import { PopoverModule, TypeaheadModule } from "ngx-bootstrap";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateService } from "@ngx-translate/core";
import { NouisliderModule } from "ng2-nouislider";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BootstrapModalModule, DialogOptions } from "ng2-bootstrap-modal";
import { NgxSelectModule } from "ngx-select-ex";
import { TabsModule } from "ngx-bootstrap/tabs";
import { MaterialModule } from "../../material.module";

import { PasswordStrengthComponent } from "./password-strength/password-strength.component";
import { LanguageSelectComponent } from "./language-select/language-select.component";
import { LoaderComponent } from "./loader/loader.component";
import { ModalConfirmComponent } from "./modal-confirm/modal-confirm.component";
import { PreviousPageComponent } from "./previous-page/previous-page.component";
import { ContractImplementorComponent } from "./contract-implementor/contract-implementor.component";
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  imports: [
    CommonModule,
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    ReactiveFormsModule,
    BootstrapModalModule.forRoot({ container: document.body }),
    HttpClientModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    PasswordStrengthComponent,
    LanguageSelectComponent,
    LoaderComponent,
    ModalConfirmComponent,
    PreviousPageComponent,
    ContractImplementorComponent
  ],
  providers: [HttpClient, TranslateService],
  exports: [
    PasswordStrengthComponent,
    TranslateModule,
    LanguageSelectComponent,
    NouisliderModule,
    ContractImplementorComponent,
    LoaderComponent,
    ProgressbarModule,
    AccordionModule,
    ModalConfirmComponent,
    TypeaheadModule,
    NgxSelectModule,
    PreviousPageComponent,
    TabsModule
  ]
})
export class SharedModule { }
