import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../../modules/login/login/login.component';
import { AuthService } from '../../modules/auth/auth.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

export interface LoginDialogData {
  username: string;
}

@Component({
  selector: "sidenav",
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  @ViewChild('sidenav', { static: false })
  sidenav: MatSidenav;
  reason = '';

  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private router: Router,
    public authService: AuthService
  ) {
    this.authService.getUserTimedOutObservable()
      .subscribe((username) => {
        const dialogRef = this.dialog.open(LoginComponent, {
          data: { username: username }
        });
        // alert('User should be logged out, default username to: ' + username);
      });

    this.authService.getUserAboutToGetTimedOutObservable()
      .subscribe((show) => {
        if (show) {
          console.log('Show warning for timeout');
        } else
          if (show) {
            console.log('Hide warning for timeout');
          }
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      //  width: "450px"
    });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  logout() {
    this.router.navigate(['/']);
  }

  close(reason: string) {
    this.reason = reason;
    // this.sidenav.close();
  }
}
