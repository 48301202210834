import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { LoaderService } from './loader.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'loader',
    templateUrl: './loader.component.html',
    styleUrls: ['../shared/loader/loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {
    @Output() loaderChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    showLoader: boolean;
    tempShow: boolean;
    constructor(
        private loaderService: LoaderService,
        private changeDetectorRef: ChangeDetectorRef,
        public loaderHttp: HttpClient) { }

    ngOnInit() {
        this.loaderService.getLoaderObservable()
            .subscribe((value: boolean) => {
                this.tempShow = value;
                if (this.tempShow) {
                    this.tempShow = true;
                    // Delay loader with 300ms
                    window.setTimeout(() => {
                        // Check if we still are supposed to show loader
                        if (this.tempShow) {
                            this.showLoader = this.tempShow;
                            this.loaderChange.emit(this.tempShow);
                            this.changeDetectorRef.markForCheck();
                        }
                    }, 300);
                } else {
                    this.showLoader = false;
                    this.loaderChange.emit(false);
                    this.changeDetectorRef.markForCheck();
                }
            });
    }
}