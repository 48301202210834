import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { AuthService } from './auth.service';
import { ContractsService } from '../../helpers/services/contracts.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService, private contractsService: ContractsService) { }

    canActivate() {
        if (this.authService.loggedIn()) {

            // check if logged in user has mandatory un-signed contracts so redirect to contracts page
            if (this.contractsService.routeToContracts()) {
                console.log('unsigned contracts exist');
                this.router.navigate(['/contracts']);
                return false;
            }

            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigate(['/home']);
        return false;
    }
}

// Guard to be used in when an url needs to be restrcited to 'SiteAdmin' role
@Injectable()
export class SiteAdminGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate() {
        if (this.authService.loggedIn() && this.authService.getRoleName() && this.authService.getRoleName().indexOf('SiteAdmin') > -1) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigate(['/home']);
        return false;
    }
}


// Guard to be used in when an url needs to be restrcited to 'Administrator' role
@Injectable()
export class AdministerGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate() {
        if (this.authService.loggedIn() && this.authService.getRoleName() && this.authService.getRoleName().indexOf('Administrator') > -1) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigate(['/home']);
        return false;
    }
}

// Guard to be used in when an url needs to be restrcited to 'Administrator' or 'SiteAdmin' roles
@Injectable()
export class AdministratorSiteAdminGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate() {
        if (this.authService.loggedIn() && this.authService.getRoleName() &&
            (this.authService.getRoleName().indexOf('Administrator') > -1 ) || (this.authService.getRoleName().indexOf('SiteAdmin') > -1)) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigate(['/home']);
        return false;
    }
}
