import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './modules/auth/auth.service';

@Injectable()
export class NewTokenInterceptor implements HttpInterceptor {


    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // return next.handle(req).pipe((e) => finalize((e) => this.responseInterceptor(e)));
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                this.responseInterceptor(event);
                return event;
            }));
    }

    private responseInterceptor(event: HttpEvent<any>): void {
        //console.log('NewTokenInterceptor event', event);
        if (event instanceof HttpResponse && event.ok) {
            const token = event.headers.get('new-token');
            if (token) {
                console.log('NewTokenInterceptor', token);
                this.authService.setToken(token);
            }
        }
    }
}
