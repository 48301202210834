import { Component, Inject, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, StateService, LoginResult } from '../../auth/index';
import { environment } from '../../../../environments/environment';
import { ContractsService } from '../../../helpers/services/contracts.service';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginDialogData } from '../../../components/sidenav/sidenav.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  frm: FormGroup;
  smsFrm: FormGroup;
  email = new FormControl('', [Validators.required, Validators.email]);

  public errorMessage: any;
  public username: string;
  public password: string;
  public smsCode: string;
  public showSmsCode: boolean;
  public showServerError = false;
  public showSMSCodeError = false;
  private hostPath: string = JSON.parse(environment.IDENTITY);

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    public authService: AuthService,
    public stateService: StateService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    public translate: TranslateService,
    public contractsService: ContractsService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: LoginDialogData
  ) { }

  ngOnInit() {
    this.frm = this.fb.group({
      username: this.fb.control(null, Validators.compose([Validators.required, Validators.email])),
      password: this.fb.control(null, Validators.required)
    });
    this.smsFrm = this.fb.group({
      smsCode: this.fb.control(null, Validators.required),
    });

    if (this.data !== null && this.data.username) {
      this.frm.patchValue({
        username: this.data.username,
      });
    }

    // this.authService.logout();
    if (this.authService.loggedIn) {
      //   this.router.navigate(['/app/dashboard']);
      const userRoles = this.authService.getRoleName();
      if (this.contractsService.routeToContracts()) {
        console.log('already logged in and unsigned contracts exists');
        this.router.navigate(['/contracts']);
      } else {
        if (userRoles && userRoles.length > 0) {
          this.router.navigate(['/app/mypage/stepper']);
        } else {
          this.router.navigate(['/app/mypage/stepper']);
        }
      }
    }
  }

  closeDialog() {
    // this.dialogRef.close();
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' : this.email.hasError('email') ? 'Not a valid email' : '';
  }

  public login() {
    const frmValue = this.frm.value;
    const smsCode = this.smsFrm.value.smsCode ? this.smsFrm.value.smsCode : '';
    this.stateService
      .login(frmValue.username, frmValue.password, smsCode)
      .subscribe(
        (result: LoginResult) => {
          console.log('stateService.login ' + result);
          if (result.status === 11) {
            // this.frm.patchValue({ smsCode: '' });
            console.log(this.frm);

            this.showSmsCode = true;
            this.changeDetectorRef.markForCheck();
          }
          if (result.status === 12) {
            let message = '';
            this.translate.get('LOGIN_MODAL.ERROR').subscribe((res: string) => {
              message += res;
            });
            this._snackBar.open(message, '', {
              duration: 5000,
            });
            this.showSMSCodeError = true;
          } else if (!result.success && result.status === 7) {

            let message = '';
            this.translate.get('LOGIN_MODAL.ERROR').subscribe((res: string) => {
              message += res;
            });
            this._snackBar.open(message, '', {
              duration: 5000,
            });
            this.showServerError = true;
          } else {
            // check if user has unsigned mandatory contracts
            if (this.contractsService.checkMandatoryFlag()) {
              if (result.contractList) {
                this.authService.setContractsList(result.contractList);
                if (
                  this.contractsService.checkUnsignedContracts(
                    result.contractList
                  )
                ) {
                  this.dialogRef.close();
                  this.router.navigate(['/contracts']);
                } else {
                  this.dialogRef.close();
                  this.routeBasedOnRole();
                }
              }
            } else if (
              this.contractsService.checkUnsignedContracts(result.contractList)
            ) {
              this.authService.setContractsList(result.contractList);
              this.dialogRef.close();
              this.router.navigate(['/contracts']);
            } else {
              // if any contracts were stored in localstorage, then clear them
              this.authService.setContractsList(result.contractList);
              this.dialogRef.close();

              let message = '';
              this.translate.get('LOGIN.LOGIN_SUCCESS').subscribe((res: string) => {
                message += res;
              });
              this._snackBar.open(message, '', {
                duration: 5000,
                verticalPosition: 'bottom',
              });

              this.routeBasedOnRole();
            }
          }
        },
        (error: any) => {
          console.log('error: ' + error);
          this.errorMessage = 'Login failed';
        }
      );
  }

  public gotForgotPassword() {
    this.dialogRef.close();
    this.router.navigate(['forgotpassword']);
  }

  /**
   * This method will read the user roles and redirects to particular page based on roles
   */
  public routeBasedOnRole() {
    const userRoles = this.authService.getRoleName();
    if (userRoles && userRoles.length > 0) {
      if (
        this.authService.isAdmin() ||
        this.authService.isSiteAdmin() ||
        this.authService.isAdminOrSiteAdmin()
      ) {
        this.router.navigate(['/app/mypage/account']);
        // this.router.navigate(['/app/dashboard']);
      } else {
        this.router.navigate(['/app/404']);
      }
    } else {
      this.router.navigate(['/app/mypage/stepper']);
    }
  }
}
