
import { forkJoin as observableForkJoin, Subscription, Observable } from 'rxjs';
import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, TemplateRef, OnDestroy } from '@angular/core';
import { LoginResult } from '../../auth/loginResult';
import { AuthService } from '../../auth/auth.service';
import { StateService } from '../../auth/state.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LanguageUtilService } from '../../auth/language-util.service';
import { MatDialog } from '@angular/material/dialog';
import { IntegrityPolicyComponent } from '../../../components/integrity-policy/integrity-policy.component';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'app-active-user',
    templateUrl: './active-user.component.html',
    styleUrls: ['./active-user.component.scss']
})
export class ActiveUserComponent implements OnInit, OnDestroy {
    lang: any;
    subscription: Subscription;

    public errorMessage: any;
    public password: string;
    public confirmpassword: string;
    private hostPath: string = JSON.parse(environment.IDENTITY);
    public code: string;
    public passwordcriteriafail: boolean = false;
    public activationCodeExpired: boolean = false;
    public activationCodeError: boolean = false;

    modalRef: BsModalRef;
    isTermsChecked = false;
    showTermsError = false;
    passwordStrength = false;
    showPasswordStrength = false;

    constructor(
        public dialog: MatDialog,
        public authService: AuthService,
        public stateService: StateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: BsModalService,
        public translate: TranslateService,
        private languageUtilService: LanguageUtilService,
        private _snackBar: MatSnackBar
    ) {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.code = params['code'];
            if (params['language']) {
                this.lang = params['language'];
                this.languageUtilService.setDefaultLang(this.lang);
                this.languageUtilService.changeLang(this.lang);
            }
        });
        this.subscription = this.languageUtilService.langChangeObservable.subscribe(event => {
            this.lang = event;
            this.router.navigate(['/activateuser'], { queryParams: { code: this.code, language: this.lang } });
        });
    }

    ngOnInit() {
        // this.authService.logout();
        // this.username = 'operations@molndrift.com';
        // this.password = 'password';
        // this.smsCode = '';
    }

    public activateUser() {
        this.passwordcriteriafail = false;
        this.activationCodeExpired = false;
        this.activationCodeError = false;
        if (this.passwordStrength) {
            this.stateService.activateUser(this.code, this.password)
                .subscribe((result: LoginResult) => {
                    if (result.status === 21) {
                        this.passwordcriteriafail = true;
                        this.changeDetectorRef.markForCheck();
                    } else if (result.status === 27) {
                        this.activationCodeExpired = true;
                        this.changeDetectorRef.markForCheck();
                    } else if (result.status === 28) {
                        this.activationCodeError = true;
                        this.changeDetectorRef.markForCheck();
                    } else {
                        let message = '';
                        this.translate.get('ERROR_MSG.ACCOUNT_ACTIVATE_SUCCESS').subscribe((res: string) => {
                            message += res;
                        });

                        this._snackBar.open(message, '', {
                            duration: 5000,
                        });
                        // alert('Account activated, sorry for the alert!');
                        this.router.navigate(['/']);
                    }
                }, (error: LoginResult) => {
                    console.log(error);
                    this.errorMessage = 'Login failed';
                });
        } else {
            this.showPasswordStrength = true;
        }
    }

    openTermsModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    isValid(e) {
        this.passwordStrength = e;
        (e) ? this.showPasswordStrength = false : this.showPasswordStrength = true;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(IntegrityPolicyComponent, {
            autoFocus: false,
        });
    }
}
