export const MATCHEDIN = {

  APIS: {
    // Include all  API endpoints/URLS used through out the application

    // right now added only login page related api urls
    'LOGIN': 'cjsf/token',
    'ACTIVATE_USER': 'cjsf/user/activate',
    'FORGOT_PASSWORD': 'cjsf/user/forgotpassword',
    'RESET_PASSWORD': 'cjsf/user/resetpassword'
  },

  CONSTANTS: {
    MATCH_FORM_QUESTION_OPARATORS: ['GREATER', 'LESS', 'EQUAL'],
    DEFAULT_LANG: 'en',
    LANGUAGES: ['English', 'Svenska'],
    LANGUAGESFULLSORT: [
      {
        letter: 'A',
        language: ['Abkhazian',
          'Afar',
          'Afrikaans',
          'Akan',
          'Albanian',
          'Amharic',
          'Arabic',
          'Aragonese',
          'Armenian',
          'Assamese',
          'Avaric',
          'Avestan',
          'Aymara',
          'Azerbaijani']
      },
      {
        letter: 'B',
        language: ['Bambara',
          'Bashkir',
          'Basque',
          'Belarusian',
          'Bengali',
          'Bihari languages',
          'Bislama',
          'Bosnian',
          'Breton',
          'Bulgarian',
          'Burmese']
      },
      {
        letter: 'C',
        language: ['Catalan, Valencian',
          'Chamorro',
          'Chechen',
          'Chichewa, Chewa, Nyanja',
          'Chinese',
          'Chuvash',
          'Cornish',
          'Corsican',
          'Cree',
          'Croatian',
          'Czech']
      },
      {
        letter: 'D',
        language: ['Danish',
          'Divehi, Dhivehi, Maldivian',
          'Dutch, Flemish',
          'Dzongkha']
      },
      {
        letter: 'E',
        language: ['English',
          'Esperanto',
          'Estonian',
          'Ewe']
      },
      {
        letter: 'F',
        language: ['Faroese',
          'Fijian',
          'Finnish',
          'French',
          'Fulah']
      },
      {
        letter: 'G',
        language: ['Galician',
          'Georgian',
          'German',
          'Greek (modern)',
          'Guaraní',
          'Gujarati']
      },
      {
        letter: 'H',
        language: ['Haitian, Haitian Creole',
          'Hausa',
          'Hebrew (modern)',
          'Herero',
          'Hindi',
          'Hiri Motu',
          'Hungarian']
      },
      {
        letter: 'I',
        language: ['Interlingua',
          'Indonesian',
          'Interlingue',
          'Irish',
          'Igbo',
          'Inupiaq',
          'Ido',
          'Icelandic',
          'Italian']
      },
      {
        letter: 'J',
        language: ['Japanese',
          'Javanese']
      },
      {
        letter: 'K',
        language: ['Kalaallisut, Greenlandic',
          'Kannada',
          'Kanuri',
          'Kashmiri',
          'Kazakh',
          'Central Khmer',
          'Kikuyu, Gikuyu',
          'Kinyarwanda',
          'Kirghiz, Kyrgyz',
          'Komi',
          'Kongo',
          'Korean',
          'Kurdish',
          'Kuanyama, Kwanyama']
      }, {
        letter: 'L',
        language: ['Latin',
          'Luxembourgish, Letzeburgesch',
          'Ganda',
          'Limburgan, Limburger, Limburgish',
          'Lingala',
          'Lao',
          'Lithuanian',
          'Luba-Katanga',
          'Latvian']
      },
      {
        letter: 'M',
        language: ['Manx',
          'Macedonian',
          'Malagasy',
          'Malay',
          'Malayalam',
          'Maltese',
          'Maori',
          'Marathi',
          'Marshallese',
          'Mongolian']
      },
      {
        letter: 'N',
        language: ['Nauru',
          'Navajo, Navaho',
          'North Ndebele',
          'Nepali',
          'Ndonga',
          'Norwegian Bokmål',
          'Norwegian Nynorsk',
          'Norwegian', 
          'Sichuan Yi, Nuosu',
          'South Ndebele']
      },
      {
        letter: 'O',
        language: ['Occitan',
        'Ojibwa',
        'Church Slavic', 
        'Church Slavonic', 
        'Old Church Slavonic', 
        'Old Slavonic', 
        'Old Bulgarian',
        'Oromo',
        'Oriya',
        'Ossetian, Ossetic']
      },
      {
        letter: 'P',
        language: ['Panjabi, Punjabi',
        'Pali',
        'Persian',
        'Polish',
        'Pashto, Pushto',
        'Portuguese']
      },
      {
        letter: 'Q',
        language: ['Quechua']
      },
      {
        letter: 'R',
        language: ['Romansh',
        'Rundi',
        'Romanian, Moldavian, Moldovan',
        'Russian']
      },
      {
        letter: 'S',
        language: ['Sanskrit',
        'Sardinian',
        'Sindhi',
        'Northern Sami',
        'Samoan',
        'Sango',
        'Serbian',
        'Gaelic, Scottish Gaelic',
        'Shona',
        'Sinhala, Sinhalese',
        'Slovak',
        'Slovene',
        'Somali',
        'Southern Sotho',
        'Spanish, Castilian',
        'Sundanese',
        'Swahili',
        'Swati',
        'Swedish']
      },
      {
        letter: 'T',
        language: ['Tamil',
        'Telugu',
        'Tajik',
        'Thai',
        'Tigrinya',
        'Tibetan',
        'Turkmen',
        'Tagalog',
        'Tswana',
        'Tongan (Tonga Islands)',
        'Turkish',
        'Tsonga',
        'Tatar',
        'Twi',
        'Tahitian']
      },
      {
        letter: 'U',
        language: ['Uighur, Uyghur',
        'Ukrainian',
        'Urdu',
        'Uzbek']
      },
      {
        letter: 'V',
        language: ['Venda',
        'Vietnamese',
        'Volapük']
      },
      {
        letter: 'W',
        language: ['Walloon',
        'Welsh',
        'Wolof',
        'Western Frisian']
      },
      {
        letter: 'X',
        language: ['Xhosa']
      },
      {
        letter: 'Y',
        language: ['Yiddish',
        'Yoruba']
      },
      {
        letter: 'Z',
        language: ['Zhuang, Chuang',
        'Zulu']
      },
    ],

    LANGUAGESFULL: ['Abkhazian',
      'Afar',
      'Afrikaans',
      'Akan',
      'Albanian',
      'Amharic',
      'Arabic',
      'Aragonese',
      'Armenian',
      'Assamese',
      'Avaric',
      'Avestan',
      'Aymara',
      'Azerbaijani',
      'Bambara',
      'Bashkir',
      'Basque',
      'Belarusian',
      'Bengali',
      'Bihari languages',
      'Bislama',
      'Bosnian',
      'Breton',
      'Bulgarian',
      'Burmese',
      'Catalan, Valencian',
      'Chamorro',
      'Chechen',
      'Chichewa, Chewa, Nyanja',
      'Chinese',
      'Chuvash',
      'Cornish',
      'Corsican',
      'Cree',
      'Croatian',
      'Czech',
      'Danish',
      'Divehi, Dhivehi, Maldivian',
      'Dutch, Flemish',
      'Dzongkha',
      'English',
      'Esperanto',
      'Estonian',
      'Ewe',
      'Faroese',
      'Fijian',
      'Finnish',
      'French',
      'Fulah',
      'Galician',
      'Georgian',
      'German',
      'Greek (modern)',
      'Guaraní',
      'Gujarati',
      'Haitian, Haitian Creole',
      'Hausa',
      'Hebrew (modern)',
      'Herero',
      'Hindi',
      'Hiri Motu',
      'Hungarian',
      'Interlingua',
      'Indonesian',
      'Interlingue',
      'Irish',
      'Igbo',
      'Inupiaq',
      'Ido',
      'Icelandic',
      'Italian',
      'Inuktitut',
      'Japanese',
      'Javanese',
      'Kalaallisut, Greenlandic',
      'Kannada',
      'Kanuri',
      'Kashmiri',
      'Kazakh',
      'Central Khmer',
      'Kikuyu, Gikuyu',
      'Kinyarwanda',
      'Kirghiz, Kyrgyz',
      'Komi',
      'Kongo',
      'Korean',
      'Kurdish',
      'Kuanyama, Kwanyama',
      'Latin',
      'Luxembourgish, Letzeburgesch',
      'Ganda',
      'Limburgan, Limburger, Limburgish',
      'Lingala',
      'Lao',
      'Lithuanian',
      'Luba-Katanga',
      'Latvian',
      'Manx',
      'Macedonian',
      'Malagasy',
      'Malay',
      'Malayalam',
      'Maltese',
      'Maori',
      'Marathi',
      'Marshallese',
      'Mongolian',
      'Nauru',
      'Navajo, Navaho',
      'North Ndebele',
      'Nepali',
      'Ndonga',
      'Norwegian Bokmål',
      'Norwegian Nynorsk',
      'Norwegian',
      'Sichuan Yi, Nuosu',
      'South Ndebele',
      'Occitan',
      'Ojibwa',
      'Church Slavic, Church Slavonic, Old Church Slavonic, Old Slavonic, Old Bulgarian',
      'Oromo',
      'Oriya',
      'Ossetian, Ossetic',
      'Panjabi, Punjabi',
      'Pali',
      'Persian',
      'Polish',
      'Pashto, Pushto',
      'Portuguese',
      'Quechua',
      'Romansh',
      'Rundi',
      'Romanian, Moldavian, Moldovan',
      'Russian',
      'Sanskrit',
      'Sardinian',
      'Sindhi',
      'Northern Sami',
      'Samoan',
      'Sango',
      'Serbian',
      'Gaelic, Scottish Gaelic',
      'Shona',
      'Sinhala, Sinhalese',
      'Slovak',
      'Slovene',
      'Somali',
      'Southern Sotho',
      'Spanish, Castilian',
      'Sundanese',
      'Swahili',
      'Swati',
      'Swedish',
      'Tamil',
      'Telugu',
      'Tajik',
      'Thai',
      'Tigrinya',
      'Tibetan',
      'Turkmen',
      'Tagalog',
      'Tswana',
      'Tongan (Tonga Islands)',
      'Turkish',
      'Tsonga',
      'Tatar',
      'Twi',
      'Tahitian',
      'Uighur, Uyghur',
      'Ukrainian',
      'Urdu',
      'Uzbek',
      'Venda',
      'Vietnamese',
      'Volapük',
      'Walloon',
      'Welsh',
      'Wolof',
      'Western Frisian',
      'Xhosa',
      'Yiddish',
      'Yoruba',
      'Zhuang, Chuang',
      'Zulu'],
    LICENCE_TYPE: ['AM', 'A1', 'A2', 'A', 'B', 'Övergångsregel B', 'Utökad B', 'BE', 'C1', 'C1E', 'C', 'CE', 'D1', 'D1E', 'D', 'DE'],
    SPECIAL_LICENCE: ['Truckkort A', 'Truckkort B', 'Motorsåg', 'Röjsåg', 'Heta arbeten', 'YKB'],
    ROLES: {
      'ADMINISTRATOR': {
        'SIDE_MENU': [
          /** Each object consist of following
           * displayname: what text will be displayed in sidemenu,
           *              Here since we have localization the name are keys from /assets/i18n/lang.json files
           * routerLink: url of the page w.r.t routes defined
           * icon: class name of the font-awesome icon to be used with menu item
           */
          { 'displayName': 'SIDEBAR.My Page', 'routerLink': 'mypage', 'icon': 'fa-user' },
          // {'displayName': 'SIDEBAR.Dashboard', 'routerLink': 'dashboard', 'icon': 'fa-home'},
          { 'displayName': 'SIDEBAR.Enrollment', 'routerLink': 'enrollment', 'icon': 'fa-key' },
          // {'displayName': 'SIDEBAR.Connect Users', 'routerLink': 'enrollment', 'icon': 'fa-key'},
          { 'displayName': 'SIDEBAR.List Users', 'routerLink': 'users', 'icon': 'fa-users' },
          { 'displayName': 'SIDEBAR.Questions', 'routerLink': 'questionform', 'icon': 'fa-home' },
          { 'displayName': 'SIDEBAR.Match Form', 'routerLink': 'matchform', 'icon': 'fa-home' },
          // {'displayName': 'SIDEBAR.Another Page', 'routerLink': 'another', 'icon': 'fa-tree'}
        ],
        'PERMISSIONS': []
      },
      'USER': {
        'SIDE_MENU': [
          { 'displayName': 'SIDEBAR.My Page', 'routerLink': 'mypage', 'icon': 'fa-user' },
          { 'displayName': 'SIDEBAR.Questions', 'routerLink': 'questionform', 'icon': 'fa-home' }
        ],
        'PERMISSIONS': []
      }
    }
  },

  MESSAGES: {},

  ROUTERLINKS: {
    /*
    *Include all the frontend routes
    */

    // Main routes
    'APP': 'app',
    'LOGIN': 'login',
    'ACTIVATE_USER': 'activateUser',
    'RESET_PASSWORD': 'resetPassword',
    'ERROR': 'error',
    'HOME': 'home',

    // layout module
    // all routes prceded by /app/
    'DASHBOARD': 'dashboard',
    'MYPAGE': 'mypage',
    'ENROLLMENT': 'enrollment',
    'QUESTION_FORM': 'questionform',
    'MATCH_FORM': 'matchform',
    'USER_PROFILE': 'user-profile',
    'DETAIL': 'detail',
    'SEARCH': 'search',
    'USERS': 'users',
    'USER_LIST': 'users-list',
    'MATCHED_USER_PROFILE': 'matched-user-profle'
  },

  URL: {},

  VALIDATOR_MESSAGES: {},

  PLACEHOLDERS: {}
};
