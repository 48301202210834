import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from "@angular/common/http";

// custom components
import { AuthGuard, StateService } from "../auth";
import { LoginComponent } from "./login/login.component";
import { ActiveUserComponent } from "./active-user/active-user.component";
import { ForgetPasswordComponent } from "./forget-password/forget-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SharedModule } from "../shared/shared.module";
import { ContractsComponent } from "./contracts/contracts.component";
import { ContractsService } from "../../helpers/services/contracts.service";
import { ModalConfirmComponent } from "../shared/modal-confirm/modal-confirm.component";
import { JWTInterceptor } from "../../jwtInterceptor";
import { MaterialModule } from "../../material.module";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HomeComponent } from "../home/home.component";

export const routes = [
  { path: "", component: HomeComponent, pathMatch: "full" },
  { path: "activateuser", component: ActiveUserComponent, pathMatch: "full" },
  {
    path: "forgotpassword",
    component: ForgetPasswordComponent,
    pathMatch: "full"
  },
  {
    path: "resetpassword",
    component: ResetPasswordComponent,
    pathMatch: "full"
  },
  { path: "contracts", component: ContractsComponent, pathMatch: "full" }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forChild(routes),
    SharedModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  declarations: [
    LoginComponent,
    ActiveUserComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    ContractsComponent
  ],
  providers: [
    AuthGuard,
    StateService,
    HttpClient,
    ContractsService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true
    }
  ],
  entryComponents: [LoginComponent]
})
export class LoginModule {}
