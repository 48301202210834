import { Component, Input } from '@angular/core';
import { ToasterType } from '../toaster.service';

@Component({
  selector: '[toaster]',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})

export class ToasterComponent {
  @Input() type: ToasterType;
  @Input() header: string;
  @Input() message: string;
  @Input() iconPath: string;
}

