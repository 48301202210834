import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class CommunicationService {

    constructor() { }

    private loaderSource = new Subject<any>();
    loaderChangeEmitted$ = this.loaderSource.asObservable();

    private sidebar = new Subject<any>();
    sidebarChangeEmitted$ = this.sidebar.asObservable();

    // Service message
    loaderChange(show: any) {
        this.loaderSource.next(show);
    }

    //
    sidebarChange(show: any) {
        this.sidebar.next(show);
    }
}
