import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, LoginResult, StateService } from '../../auth';
import { TranslateService } from '@ngx-translate/core';
import { ContractsService } from '../../../helpers/services/contracts.service';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
    // WARNING: Do not change to ng2 bootstrap modal!!!

    public username: string;
    public password: string;
    public smsCode: string;
    public showModal: boolean;
    public showSmsCode: boolean;

    public errorMessage: any;

    constructor(
        private authService: AuthService,
        private stateService: StateService,
        private router: Router,
        public translate: TranslateService,
        private contractService: ContractsService,
        private changeDetectorRef: ChangeDetectorRef) {
        this.authService.getUserTimedOutObservable()
            .subscribe((username) => {
                this.show(username);
            });
    }

    ngOnInit() {
    }

    public show(username: string) {
        this.username = username;
        this.showModal = true;
        this.password = '';
        this.smsCode = '';
        this.showSmsCode = false;
        this.changeDetectorRef.markForCheck();
    }

    public hide() {
        this.username = undefined;
        this.showModal = false;
        this.showSmsCode = false;
        this.errorMessage = undefined;
        this.changeDetectorRef.markForCheck();
    }

    public login() {
        this.stateService.login(this.username, this.password, this.smsCode)
            .subscribe((result: LoginResult) => {
                if (result.status === 11) {
                    this.smsCode = '';
                    this.showSmsCode = true;
                    this.changeDetectorRef.markForCheck();
                } else if (result.status === 7) {
                    this.translate.get('LOGIN_MODAL.ERROR').subscribe(obj => {
                        this.errorMessage = obj;
                    });

                } else {
                    this.hide();
                    if (result.contractList) {
                        this.authService.setContractsList(result.contractList);
                        this.contractService.contractsUpdated();
                    }
                }
            }, (error: LoginResult) => {
                console.log(error);
                this.errorMessage = 'Login failed';
            });
    }
}
