import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable ,  BehaviorSubject ,  Subject } from 'rxjs';

import 'rxjs/Rx';

import { MemberRight } from './memberRight';
import { MemberType } from './memberType';
import { LoginResult } from './loginResult';

import { environment } from '../../../environments/environment';


@Injectable()
export class AuthService {
    jwtHelperService: JwtHelperService;
    private isLoggedIn: boolean;
    private token: string;
    private role: string;
    public username: string;
    public userId: string;
    public hasMandatoryUnsignedContracts: string;
    private memberRights: MemberRight[];
    private hostPath: string = JSON.parse(environment.IDENTITY);

    private userTimedOutSubject: Subject<string> = new Subject<string>();
    private userTimedOutObservable: Observable<string>;

    private soonTimedOutSubject: Subject<boolean> = new Subject<boolean>();
    private soonTimedOutObservable: Observable<boolean>;

    public displayName: string;
    private accessTest: string;


    constructor(public router: Router) {
        this.jwtHelperService = new JwtHelperService();
        this.userTimedOutObservable = this.userTimedOutSubject.asObservable();
        this.soonTimedOutObservable = this.soonTimedOutSubject.asObservable();

        this.token = this.getToken();
        if (this.isTokenValid()) {
            this.setTokenValues(this.token);
        }

        setInterval(() => {
            this.checkUserTimedOut();
            this.checkAboutToGetTimedOut();
        }, 5000);

    }

    private checkUserTimedOut() {
        if (this.token && this.jwtHelperService.isTokenExpired(this.token)) {
            let exp = this.jwtHelperService.getTokenExpirationDate(this.token);
            let maxDate = new Date(exp.getTime() + 3000);
            console.log('warning: ', ' exp:', exp, ' maxDate', maxDate);
            if (new Date().getTime() >= maxDate.getTime()) {
                this.userTimedOutSubject.next(this.username);
                this.clearTokenValues();
                console.log('User has timed out');
            }
        }
    }

    public forceTimeOut() {
        this.removeContractsList();
        this.userTimedOutSubject.next(this.username);
        this.clearTokenValues();
        // forces users to see login modal
    }

    public getUserTimedOutObservable() {
        return this.userTimedOutObservable;
    }

    private checkAboutToGetTimedOut() {
        if (this.token) {
            let exp = this.jwtHelperService.getTokenExpirationDate(this.token);
            // show notification before 20 mins of expiry
            let notificationTime = new Date(exp.getTime() - (8 * 1000 * 60));
            if ((new Date().getTime() >= notificationTime.getTime()) && !this.jwtHelperService.isTokenExpired(this.token)) {
                this.soonTimedOutSubject.next(true);
            } else {
                this.soonTimedOutSubject.next(false);
            }
        } else {
            this.soonTimedOutSubject.next(false);
        }
    }

    public forceTimeOutWarning() {
        this.soonTimedOutSubject.next(true);
    }

    public getUserAboutToGetTimedOutObservable() {
        return this.soonTimedOutObservable;
    }

    private clearTokenValues() {
        // Call token revoke
        this.isLoggedIn = false;
        this.token = null;
        this.role = null;
        this.username = null;
        this.userId = null;
        this.hasMandatoryUnsignedContracts= null;
        localStorage.removeItem('id_token');
        this.removeContractsList();
    }

    public setToken(token) {
        this.token = token;
        localStorage.setItem('id_token', token);
        this.setTokenValues(token);
    }

    public setContractsList(contracts){
        localStorage.setItem('ContractsList', JSON.stringify(contracts));
    }

    public removeContractsList(){
        if (localStorage.getItem('ContractsList') != null) {
            localStorage.removeItem('ContractsList');
        }
    }

    public refreshToken() {
        console.log('AuthService - refreshToken()');
    }

    private isTokenValid() {
        if (this.token) {
            return !this.jwtHelperService.isTokenExpired(this.token);
        }
        return false;
    }


    private setTokenValues(token) {
        if (token) {
            var decodedToken = this.jwtHelperService.decodeToken(token);
            this.isLoggedIn = true;
            this.role = decodedToken.role;
            this.userId = decodedToken.sub;
            this.displayName = decodedToken.displayname;
            this.username = decodedToken.username;
            this.hasMandatoryUnsignedContracts = decodedToken.hasMandatoryUnsignedContracts;
            this.setAppInsightsAuthId(decodedToken.sub);
        }
    }

    private setAppInsightsAuthId(id: string) {
        var validatedId = id.replace(/[,;=| ]+/g, '_');
        // appInsights.setAuthenticatedUserContext(validatedId);
    }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.token = null;
        this.role = null;
        this.displayName = null;
        this.userId = null;
        this.accessTest = null;
        // localStorage.removeItem('currentUser');
        localStorage.removeItem('id_token');

        // clear any contracts that were stored in localstorage
        this.removeContractsList();
        this.clearTokenValues();
        this.soonTimedOutSubject.next(false);
        this.router.navigate(['/']);
    }

    loggedIn() {
        return this.isLoggedIn;
    }

    getToken(): string {
        if (localStorage.getItem('id_token') != null) {
            if (this.jwtHelperService.isTokenExpired(localStorage.getItem('id_token'))) {
                return null;
            }
        }
        return localStorage.getItem('id_token');
    }

    getRoleName() {
        return this.role;
    }

    getFullUserName() {
        return this.displayName;
    }

    getUserName() {
        return this.username;
    }

    getHasMandatoryUnsignedContracts() {
        return this.hasMandatoryUnsignedContracts;
    }

    getUserId() {
        return this.userId;
    }

    getTokenExpirationDate() {
        return this.jwtHelperService.getTokenExpirationDate(this.getToken());
    }

    /* Utility method helpful to check whether logged in user has 'Administrator' role.
    * Can be used in *ngIf in HTML pages to show/hide elements based on role
    */
    isAdmin() {
        if (this.loggedIn() && this.getRoleName() && this.getRoleName().indexOf('Administrator') > -1) {
            return true;
        } else {
            return false;
        }
    }

    /* Utility method helpful to check whether logged in user has 'SiteAdmin' role.
    * Can be used in *ngIf in HTML pages to show/hide elements based on role
    */
    isSiteAdmin() {
        if (this.loggedIn() && this.getRoleName() && this.getRoleName().indexOf('SiteAdmin') > -1) {
            return true;
        } else {
            return false;
        }
    }

    /* Utility method helpful to check whether logged in user has any one role i.e, 'SiteAdmin' or 'Administrator' .
    * Can be used in *ngIf in HTML pages to show/hide elements based on role
    */
    isAdminOrSiteAdmin() {
        if (this.loggedIn() && this.getRoleName() &&
            (this.getRoleName().indexOf('Administrator') > -1) || (this.getRoleName().indexOf('SiteAdmin') > -1)) {
            return true;
        } else {
            return false;
        }
    }
}
