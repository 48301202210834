import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Toaster, ToasterService, ToasterType } from '../toaster.service';
import { AuthService } from '../../auth/auth.service';

export const idGenerator = (prefix = '') => {
  let _id = 0;
  return () => prefix + ++_id;
};
const idFn = idGenerator();

const logoutToasterId = '_logout-toaster';
const getIcon = (type: ToasterType): string => {
  if (type === 'success') {
    return '/assets/img/icons/check.svg';
  }
  if (type === 'danger') {
    return '/assets/img/icons/exclamation-red.svg';
  }
};
@Component({
  selector: 'toasters',
  templateUrl: './toasters.component.html',
  styleUrls: ['./toasters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500)
      ]),
      transition(':leave', animate(500, style({ opacity: 0 })))
    ])
  ]
})
export class ToastersComponent {

  private timeouts = {};
  private _toasters: Toaster[] = [];
  public get toasters(): Toaster[] {
    return this._toasters;
  }

  private logoutToaster: Toaster = {
    id: logoutToasterId,
    type: 'danger',
    icon: getIcon('danger'),
    header: 'LOGOUT_WARNING.MSG',
    click: () => this.toasterService.keepAlive(),
    timeout: 0
  };

  constructor(authService: AuthService, private toasterService: ToasterService, private changeDetector: ChangeDetectorRef) {
    authService.getUserAboutToGetTimedOutObservable()
      .subscribe((showToaster: boolean) => {
        if (showToaster) {
          this.addToaster(this.logoutToaster, true);
        }
        else
        {
          this.removeToaster(logoutToasterId);
        }
      });
    authService.getUserTimedOutObservable()
      .subscribe(() => {
        this.removeToaster(logoutToasterId);
      });
    toasterService.toasterAdded()
      .subscribe((toaster) => {
        this.addToaster(toaster);
      });
  }

  private addToaster(toaster, prepend = false) {
    if (this.hasToaster(toaster)) {
      return;
    }

    if (toaster.id == null) {
      toaster.id = idFn();
    }
    if (toaster.icon == null) {
      toaster.icon = getIcon(toaster.type);
    }
    if (toaster.timeout == null) {
      toaster.timeout = 3000;
    }
    if (toaster.timeout !== 0) {
      this.addTimeout(toaster.id, setTimeout(() => {
        this.removeToaster(toaster.id);
      }, toaster.timeout));
    }
    if (prepend) {
      this._toasters = [toaster, ...this._toasters];
    } else {
      this._toasters = [...this._toasters, toaster];
    }
    this.changeDetector.markForCheck();
  }

  private hasToaster({ id: toasterId }) {
    return this._toasters.some(({ id }) => id === toasterId);
  }

  private removeToaster(toasterId) {
    this._toasters = this._toasters.filter(({ id }) => id !== toasterId);
    this.changeDetector.markForCheck();
  }

  private addTimeout(toasterId, timeoutId) {
    this.removeTimeout(toasterId);
    this.timeouts[toasterId] = timeoutId;
  }

  private removeTimeout(toasterId) {
    if (this.timeouts[toasterId] != null) {
      clearTimeout(this.timeouts[toasterId]);
      delete this.timeouts[toasterId];
    }
  }

  public onClick(toaster, e) {
    if (toaster.click != null) {
      toaster.click(e);
    }

    this.removeTimeout(toaster.id);
    this.removeToaster(toaster.id);
  }

  public toasterId(i, { id }) {
    return id;
  }
}

