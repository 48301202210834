import { Component, OnChanges, Input, SimpleChange, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnInit, OnChanges {
  @Input() passwordToCheck: string;
  @Input() controlLabel: string;
  @Output('onStatusChange') onStatusChange = new EventEmitter<any>();
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  bar4: string;
  colors = ['#F00', '#F90', '#FF0', '#FF0', '#0F0'];

  constructor() { }

  ngOnInit() {
  }

  private  measureStrength(p) {
      let _force = 0;
      let _regex = /[$-/:-?{-~!"^_`\[\]]/g; // "

      let _lowerLetters = /[a-z]+/.test(p);
      let _upperLetters = /[A-Z]+/.test(p);
      let _numbers = /[0-9]+/.test(p);
      let _symbols = _regex.test(p);

      let _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];

      let _passedMatches = 0;
      for (let _flag of _flags) {
          _passedMatches += _flag === true ? 1 : 0;
      }

      _force +=  p.length + ((p.length >= 10) ? 1 : 0);
      _force += _passedMatches * 10;

      // penality (short password)
      _force = (p.length <= 6) ? Math.min(_force, 10) : _force;

      // penality (poor variety of characters)
      _force = (_passedMatches === 1) ? Math.min(_force, 10) : _force;
      _force = (_passedMatches === 2) ? Math.min(_force, 20) : _force;
      _force = (_passedMatches === 3) ? Math.min(_force, 40) : _force;

      return _force;

  }

  private getColor(s) {
    let idx = 0;
    if (s <= 10) {
        idx = 0;
        this.onStatusChange.emit(false);
    } else if (s <= 20) {
        idx = 1;
        this.onStatusChange.emit(false);
    } else if (s <= 30) {
        idx = 2;
        this.onStatusChange.emit(false);
    } else if (s <= 40) {
        idx = 3;
        this.onStatusChange.emit(false);
    } else {
        idx = 4;
        this.onStatusChange.emit(true);
    }
    return {
        idx: idx + 1,
        col: this.colors[idx]
    };
}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    let password = changes['passwordToCheck'].currentValue;
    this.setBarColors(5, '#DDD');
    if (password) {
      let c = this.getColor(this.measureStrength(password));
      this.setBarColors(c.idx, c.col);
    }
  }
  private setBarColors(count, col) {
    for (let _n = 0; _n < count; _n++) {
      this['bar' + _n] = col;
    }
  }

}
