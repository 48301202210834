import { Component, OnInit } from '@angular/core';

export interface InformationElement {
  legal: string;
  reason: string;
}

const ELEMENT_DATA: InformationElement[] = [
  {reason: 'För att kunna leverera matchningstjänster till dig', legal: 'Uppfylla avtal och åtaganden vi har gentemot dig'},
  {reason: 'För att kunna ge dig den hjälp och service du kan behöva rörande våra tjänster', legal: 'Uppfylla avtal och åtaganden vi har gentemot dig'},
  {reason: 'För utskick av arbetserbjudanden och liknande information', legal: 'Uppfylla avtal och åtaganden vi har gentemot dig'},
  {reason: 'För utskick av nyhetsbrev, event- och festinbjudningar', legal: 'Andra berättigade intressen'},
  {reason: 'För analys i syfte att förbättra våra tjänster för dig', legal: 'Andra berättigade intressen'},
];

@Component({
  selector: 'app-integrity-policy',
  templateUrl: './integrity-policy.component.html',
  styleUrls: ['./integrity-policy.component.scss']
})
export class IntegrityPolicyComponent implements OnInit {

  displayedColumns: string[] = ['reason', 'legal'];
  dataSource = ELEMENT_DATA;

  constructor() { }

  ngOnInit() {
  }

}
