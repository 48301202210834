import { Component, OnInit } from '@angular/core';
import {CommunicationService} from '../../../helpers/services/communication.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  show: boolean;
  constructor(private _myCommunicationService: CommunicationService) { }

  ngOnInit() {
    this._myCommunicationService.loaderChangeEmitted$.subscribe(show => {
      this.show = show;
    });
  }

}
