import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageUtilService } from "../../auth/language-util.service";

@Component({
  selector: "app-language-select",
  templateUrl: "./language-select.component.html",
  styleUrls: ["./language-select.component.scss"]
})
export class LanguageSelectComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    private languageUtilService: LanguageUtilService,
  ) {
    translate.setDefaultLang(this.languageUtilService.getDefaultLang());
    translate.currentLang = this.languageUtilService.getDefaultLang();
  }

  ngOnInit() {}

  changeLanguage(lang: any) {
    this.languageUtilService.changeLang(lang);
  }
}
